<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="Nuevo"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                 @click="this.$router.push('/piezas/crear')"
              />
               
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="Exportar"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          ref="dt"
          :value="products"
          v-model:selection="selectedProducts"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} planos"
          responsiveLayout="scroll"
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0">Piezas</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>
            </div>
          </template>


          <Column
            field="code"
            header="Código"
            :sortable="true"
            headerStyle="width:20%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Código</span>
              {{ slotProps.data.code }}
            </template>
          </Column>
          <Column
            field="name"
            header="Nombre"
            :sortable="true"
            headerStyle="width:50%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Nombre</span>
              {{ slotProps.data.name }}
            </template>
        </Column>
                  <Column
            field="plane"
            header="Plano"
            :sortable="true"
            headerStyle="width:5%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Plano</span>
<a v-if="slotProps.data.plane" 
                      :href="this.appBackend + '/uploads/' + slotProps.data.plane.file"
                      target="_blank"
                    >
                      <Button
                        icon="pi pi-file-pdf"
                        class="p-button-rounded p-button-error"
                      ></Button>
                    </a>
            </template>
        </Column>
        <Column
            field="last_price"
            header="Precio sugerido"
            :sortable="true"
            headerStyle="width:20%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Precio sugerido</span>
              {{ toArs(slotProps.data.last_price) }}

              <Button icon="pi pi-search" class="p-button-rounded p-button-success p-button-text" @click="this.$router.push('/piezas/' + slotProps.data.id + '/ver')"></Button>

            </template>
          </Column>
    <Column
            field="tiempos"
            header="Tiempos"
            :sortable="true"
            headerStyle="width:50%; min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Tiempos</span>

              <Button 
              :label="this.toTime(slotProps.data.avg_time)"
              :disabled="slotProps.data.avg_time == 0"
              icon="pi pi-clock" class="p-button-rounded p-button-sm p-button-outlined p-button-info" 
              @click="this.$router.push('/piezas/' + slotProps.data.id + '/tiempos')"></Button>

            </template>
          </Column> 
 
          <Column headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-star"
                class="p-button-rounded p-button-info mr-2"
                @click="this.$router.push('/piezas/' + slotProps.data.id + '/calidad')"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data.id)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="productDialog"
          :style="{ width: '450px' }"
          header="Plano"
          :modal="true"
          class="p-fluid"
        >
          <div class="field">
            <label for="name">Nombre </label>
            <InputText
              id="name"
              v-model.trim="product.name"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.name }"
            />
            <small class="p-invalid" v-if="submitted && !product.name"
              >Nombre es requerido.</small
            >
          </div>
          <div class="field">
            <label for="quantity">Descripción </label>
            <InputText
              id="description"
              v-model.trim="product.description"
              required="true"
              autofocus
              :class="{ 'p-invalid': submitted && !product.description }"
            />
            <small class="p-invalid" v-if="submitted && !product.description"
              >Descripción es requerido.</small
            >
          </div>
 

          <template #footer>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-text"
              @click="hideDialog"
            />
            <Button
              label="Guardar"
              icon="pi pi-check"
              class="p-button-text"
              @click="saveProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Eliminaras a <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudService from "../services/crud.service";

export default {
  data() {
    return {
      routeName: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: null,
      providers: []
    };
  },
   
  created() {
    // get route name
    this.routeName = this.$route.name;
     
    this.initFilters();
  },
  mounted() {

    CrudService.getCRUD('api/pieza').then(
      (data) => (this.products = data)
    );

  },
  methods: {
    _padNumber: number =>  (number > 9  ) ? number : "0" + number, 
        _readableTimeFromSeconds: function(seconds) {
            const hours = 3600 > seconds ? 0 : parseInt(seconds / 3600, 10)
            return {
                hours: this._padNumber(hours),
                seconds: this._padNumber(seconds % 60),
                minutes: this._padNumber(parseInt(seconds / 60, 10) % 60),
            }
        },
    toTime(value){

      if(value){
        let ret =  this._readableTimeFromSeconds(value);
        return ret.hours + ":" + ret.minutes + ":" + ret.seconds;

      }else{
        return ' Sin datos '
      }

    },
    
        toArs(value) {
 
      return value.toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
      });
    },
    giveMeName(id) {
      let exist = this.providers.find(provider => provider.id === id);

      if(!exist) {
        return ' - ';
      }else {
        return exist.name;
      }
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.product.file = files[0];
      console.log(this.product)
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          CrudService.updateCRUD('api', 'pieza', this.product).then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          CrudService.createCRUD('api', 'pieza', this.product).then(
            (data) => {
              
            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);
              
              } else {
                this.products.push(this.product);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }
    },
    editProduct(product) {
      // this.product = { ...product };
      // this.productDialog = true;

      this.$router.push({ name: 'edit-piece', params: { id: product } });
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      
      CrudService.deleteCRUD('api', 'pieza', this.product.id).then(
        (data) => {

          this.products = this.products.filter((val) => val.id !== data.data.id);

          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });
        }
      );

      this.deleteProductDialog = false;
      this.product = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
